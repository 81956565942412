import React, { useState, useEffect } from "react";
import { Header, Loader } from "Atoms/";
import Link from "next/link";
import API from "Services/api";
import { Notices } from 'Molecules/';

export default function Index() {
  const [parks, setParks] = useState("");
  const [notices, setNotices] = useState("");
  
  useEffect(() => {
    async function apiHit() {
      try {
        const { data } = await API.get("/parks/list");
        if (data?.message === "Success") {
          setParks(data?.data?.parks);
        }
      } catch (error) {
        console.log(error);
      }
    }
    apiHit();
    
    
  }, []);

  return (
    <div>
      <Header
        classType="header-int"
        title1="Let’s go to"
        title2="Hawaiʻi State Parks"
        isReserve={false}
      />
      <div className="container pt-5 pb-5">
        <Notices park="general" />
        {parks ? (
          <div className="row justify-content-center">
            {parks?.map((park) => (
              <div className="col-xl-4" key={park._id}>
                <Link
                  href={park.externalUrl ? park.externalUrl : park.parkUrl}
                >
                  <a target="_blank" style={{ textDecoration: "none" }}>
                    <div
                      className="callouts-home"
                      onClick={() => {
                        localStorage.removeItem("customer_input");
                        localStorage.removeItem("booking_data");
                      }}
                      style={{
                        backgroundImage: `url(${park.sideImage})`,
                      }}
                    >
                      <h3>{park.parkName}, {park.island}</h3>
                    </div>
                  </a>
                </Link>
              </div>
            ))}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}
